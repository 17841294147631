import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';


class SwiperCarousel{
    constructor() {
        this.addClasses();
        this.addClassesLatest();
        this.testiCarousel();
      }

      addClasses() {
        if (document.querySelector('.sale-prods .woocommerce .products')) {
            document.querySelector('.sale-prods .woocommerce').classList.add('swiper');
            document.querySelector('.sale-prods .woocommerce .products').classList.add('swiper-wrapper');
            let products = document.querySelectorAll('.sale-prods .woocommerce .products .product');
            for (let product of products) {
                product.classList.add('swiper-slide');
            }
            let paginate = document.createElement('div');
            paginate.setAttribute('class', 'swiper-pagination');
            document.querySelector('.sale-prods .woocommerce').appendChild(paginate);
            setTimeout(function() {
                const swip = new Swiper(".swiper", {
            
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    pagination: {
                      el: ".swiper-pagination",
                      clickable: true,
                      

                    },
                    // Responsive breakpoints
                    breakpoints: {
                        // when window width is >= 320px
                        340: {
                        slidesPerView: 1,
                        slidesPerGroup: 1,
                        pagination: {
                            dynamicBullets: true,
                            dynamicMainBullets: 4,
                            
      
                          }
                        },
                        782: {
                            slidesPerView: 2,
                            slidesPerGroup: 3,
                            },
                        // when window width is >= 640px
                        1151: {
                            slidesPerView: 4,
                            slidesPerGroup: 4,
                        }
                    }
                  
                
    
            });

            const swipe2 = new Swiper(".swiper2", {
            
                slidesPerView: 4,
                autoplay: {
                    delay: 2000,
                },
                
                // Responsive breakpoints
                breakpoints: {
                    // when window width is >= 320px
                    340: {
                    slidesPerView: 1,
                    pagination: {
                        dynamicBullets: true,
                        dynamicMainBullets: 4,
                        
  
                      }
                    },
                    782: {
                        slidesPerView: 2,
                        },
                    // when window width is >= 640px
                    1151: {
                        slidesPerView: 4,
                    }
                }
              
            

        });
            }, 0)
        }
      }

      addClassesLatest() {
        if (document.querySelector('.latest-prods .woocommerce .products')) {
            document.querySelector('.latest-prods .woocommerce').classList.add('swiper3');
            document.querySelector('.latest-prods .woocommerce .products').classList.add('swiper-wrapper');
            let products = document.querySelectorAll('.latest-prods .woocommerce .products .product');
            for (let product of products) {
                product.classList.add('swiper-slide');
            }
            let paginate = document.createElement('div');
            paginate.setAttribute('class', 'swiper-paginations');
            document.querySelector('.latest-prods .woocommerce').appendChild(paginate);
            setTimeout(function() {
                const swip = new Swiper(".swiper3", {
            
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    pagination: {
                      el: ".swiper-paginations",
                      clickable: true,
                      

                    },
                    // Responsive breakpoints
                    breakpoints: {
                        // when window width is >= 320px
                        340: {
                        slidesPerView: 1,
                        slidesPerGroup: 1,
                        pagination: {
                            dynamicBullets: true,
                            dynamicMainBullets: 4,
                            
      
                          }
                        },
                        782: {
                            slidesPerView: 2,
                            slidesPerGroup: 3,
                            },
                        // when window width is >= 640px
                        1151: {
                            slidesPerView: 4,
                            slidesPerGroup: 4,
                        }
                    }
                  
                
    
            });
            }, 0)
        }
      }

      testiCarousel() {
        if (document.querySelector('.swiper4')) {
        const swipe4 = new Swiper(".swiper4", {
            
            slidesPerView: 1,
            pagination: {
                el: ".swiper-paginationss",
                clickable: true,
                

              }
          

    });
}
      }

      
}

export default SwiperCarousel